import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
// import { OutboundLink } from "gatsby-plugin-google-analytics"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { device } from "../helpers/devices"
import { colors } from "../helpers/colors"

import SadNoodleImage from "../images/sad-noodle.png"
import SpotifyImage from "../images/spotify-button-fr.png"
import LogoImage from "../images/kd-logo-fr.png"

const H1Styled = styled.h1`
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  color: ${colors.kdyellow};
  text-align: center;
  margin-top: 60px;
  margin-bottom: 0px;
  font-size: 1.43em;
  @media only screen and ${device.mobileS} {
    font-size: 1.43em;
  }
  @media only screen and ${device.mobileM} {
    font-size: 1.46em;
  }
  @media only screen and ${device.mobileL} {
    font-size: 2.12em;
  }
  // @media only screen and ${device.tablet} {
  //   font-size: 2.1em;
  // }
`

const SadNoodleStyled = styled.img`
  width: 100%;
  height: 100%;
  max-width: 170px;
  margin: 35px 0 40px;

  @media only screen and ${device.mobileS} {
    max-width: 170px;
    margin: 35px 0 40px;
  }
  @media only screen and ${device.tablet} {
    max-width: 250px;
    margin: 45px 0 50px;
  }
`

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 500;
  color: #ffffff;

  font-size: 0.81em;
  @media only screen and ${device.mobileS} {
    text-align: left;
    text-align-last: left;
  }
  @media only screen and ${device.tablet} {
    text-align: justify;
    text-align-last: center;
  }
`

const TitleCol = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 530px;
  justify-content: center;
  margin-left: 50px;
  margin-right 50px;
`

const ParagraphCol = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 530px;
  justify-content: center;
  margin-left: 50px;
  margin-right 50px;
`

const SpotifyLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 40px;
  margin: 0 auto;
  margin-top: 30px;
  background-color: ${colors.kdyellow};
  border-radius: 5px;
  &:hover {
    background-color: ${colors.kdyellowHover};
  }
`

const SpotifyImageStyled = styled.img`
  display: block;
  width: 100%;
  height: auto;
  padding: 0 1.2em;
  margin: 0;
`

const KDLogoImage = styled.img`
  margin: 30px 0;
  width: 100%;
  max-width: 100px;
`

const EngFrLink = styled(Link)`
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 500;
  font-size: 0.5em;
  color: rgba(255, 255, 255, 0.5);
  padding-right: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
  }
`

const pageTitle = "On vous demande pardon."
const tabTitle = "KD demande pardon"

const metaTitle = "Lisez nos excuses"
const metaDesc =
  "Canadiens, vous avez dit des choses pas géniales sur le goût du Kraft Dinner dernièrement. Alors on tient à s'excuser."
const metaSiteName = "kddemandepardon.ca"
const metaURL = "https://kddemandepardon.ca"
// TODO: Change the image url once live!
const imageURL =
  "https://kraftsayssorry.rethinkcanada.ca/images/social_card_800x418_Fr.jpg"

const IndexPage = () => {
  return (
    <Layout backgroundURL="../images/background.svg">
      <Helmet>
        <title>{tabTitle}</title>
        <meta name="" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="og:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDesc} />
        <meta name="twitter:description" content={metaDesc} />
        <meta name="twitter:image" content={imageURL} />
        <meta name="og:image" content={imageURL} />
        <meta name="twitter:site" content={metaSiteName} />
        <meta property="og:site_name" content={metaSiteName} />
        <meta name="twitter:url" content={metaURL} />
        <meta name="og:url" content={metaURL} />
      </Helmet>
      <Row>
        <Col className="text-right" style={{ paddingTop: 20 }}>
          <EngFrLink to="/">EN / FR</EngFrLink>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <TitleCol>
          <H1Styled>{pageTitle}</H1Styled>
        </TitleCol>
      </Row>
      <Row className="justify-content-center">
        <SadNoodleStyled src={SadNoodleImage} />
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <ParagraphCol>
          <Paragraph>
            Chers Canadiens, ces derniers temps, on se promenait sur Internet et
            on est tombés sur des choses pas géniales à propos du Kraft Dinner.
            Des propos comme «&nbsp;le Kraft Dinner est bizarre&nbsp;» ou
            «&nbsp;il goûte mauvais&nbsp;». Mauvais!? Impossible. Ça ne se peut
            juste pas. En tant que délégués dévoués de ce trésor national, il
            est de notre devoir de protéger ce repas apprécié de tous. Et on a
            failli à la tâche. Après tout, le KD est le plat national non
            officiel du Canada. C’est aussi canadien que s’excuser constamment.
            Alors c’est exactement ce qu’on va faire. Ici, maintenant. On vous
            demande pardon.
          </Paragraph>
          <Paragraph>
            Et ne vous inquiétez pas. On le fait comme il faut.
          </Paragraph>
          <Paragraph>
            On a de bonnes nouvelles à vous servir. On a demandé à des Canadiens
            comme vous de participer à des tests de goût avec nous pour obtenir
            le parfait Kraft Dinner. On a amélioré la texture de nos macaronis
            et on a ajouté plus de fromage. Oui, vous avez bien lu. On a mis
            plus de fromage que jamais auparavant dans chaque boîte de KD. À
            partir de maintenant, il va goûter ce qu’il doit goûter.
          </Paragraph>
          <Paragraph>
            On ne se contente pas de régler le problème du KD; on veut aussi que
            vous sachiez à quel point on est désolés. Alors on vous offre nos
            plus sincères excuses, et ce, en nous inspirant de l’époque où les
            réconciliations se faisaient parfois à l’aide d’une demande spéciale
            à la radio. On veut s’excuser en dédiant des chansons aux Canadiens
            sur les ondes de stations de radio partout au pays. On vous dédie
            même des pièces musicales, là, maintenant, dans cette lettre, juste
            pour vous prouver à quel point on est désolés.
          </Paragraph>
          <Paragraph>
            Soyez sans crainte, on est sur le cas du Kraft Dinner. Tout va être
            parfait. C’est trop important pour faire les choses à moitié. Parce
            qu’après tout, c’est KD ou rien.
          </Paragraph>
          <Paragraph style={{ marginBottom: 0 }}>Vos amis chez KD.</Paragraph>
        </ParagraphCol>
      </Row>
      <Row>
        <Col className="text-center" xl={{ span: 12 }}>
          <SpotifyLink
            href="https://open.spotify.com/playlist/7G5BLFOd4mYu9m1zwHBTmq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SpotifyImageStyled src={SpotifyImage} />
          </SpotifyLink>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }} className="text-center">
          <KDLogoImage src={LogoImage} />
        </Col>
        {/* <Col
          xs={{ span: 2 }}
          style={{
            display: "flex",
          }}
        >
          <ScrollTopButton />
        </Col> */}
      </Row>
    </Layout>
  )
}

export default IndexPage
